<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fund.desc')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-row class="publish" v-for="item in desclist" :key="item.id">
        <van-col :span="24" style="padding:20px">
          <span
            style="color: white; display: block; margin-bottom: 10px; font-size: 20px;"
          >
            {{ item.title }}</span
          >
          <van-image style="width:80%;height:200px" :src="item.image" />
          <van-row class="publish-msg">
            <span>{{ item.content }}</span>
          </van-row>
          <van-button class="submitBtn" @click="todetail(item.id)">{{
            $t('fund.chak')
          }}</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desclist: []
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    // 获取列表
    async getlist() {
      const { data } = await this.$http.get('/home/fund/desclist')
      if (data) {
        if (data.code === 200) {
          this.desclist = data.data
        }
      }
    },
    // 查看详情
    todetail(id) {
      this.$router.push('/fund/introduction/detail/' + id)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 50px 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  height: 100vh;
  .publish {
    margin-top: 0.4rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;
    .publish-msg {
      margin-top: 0.53333rem;
      padding-left: 1.33333rem;
      padding-right: 1.33333rem;
      color: grey;
      font-size: 0.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .submitBtn {
      margin-top: 5%;
      width: 80%;
      letter-spacing: 0.53333rem;
      height: 3.2rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #004ea3;
      border-radius: 0.53333rem;
      border: none;
      box-shadow: 0 0 1.33333rem #004ea3;
    }
  }
}
</style>
